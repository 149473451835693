module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ROCKINFO","short_name":"ROCKINFO","background_color":"#FFFFFF","theme_color":"#D20014","display":"standalone","prefer_related_applications":false,"icon":"src/images/icon.png","lang":"da","localize":[{"start_url":"/da/","lang":"da"},{"start_url":"/sv/","lang":"sv"},{"start_url":"/no/","lang":"no"},{"start_url":"/fi/","lang":"fi"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"536b2838ca607ebbe7e5cd7c58fb7b30"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
