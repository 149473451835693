exports.onRouteUpdate = ({ location }) => {
  let waitTime = 1000;
  executeMathJax();

  function executeMathJax() {
    if (window.MathJax !== undefined && MathJax.Hub !== undefined) {
      MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    } else if (waitTime <= 5000) { // Exponential backoff-ish with limited tries
      setTimeout(() => executeMathJax(), waitTime);
      waitTime += 1000;
    }
  }
};

exports.onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
    `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}
