exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-language-landing-page-js": () => import("./../../../src/templates/language_landing_page.js" /* webpackChunkName: "component---src-templates-language-landing-page-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */),
  "component---src-templates-pwa-guide-js": () => import("./../../../src/templates/pwa_guide.js" /* webpackChunkName: "component---src-templates-pwa-guide-js" */),
  "component---src-templates-subchapter-js": () => import("./../../../src/templates/subchapter.js" /* webpackChunkName: "component---src-templates-subchapter-js" */)
}

